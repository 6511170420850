import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "demo-image__preview" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_image, {
      src: $data.url,
      "preview-src-list": $data.srcList,
      "initial-index": 0,
      lazy: "",
      "hide-on-click-modal": ""
    }, null, 8, ["src", "preview-src-list"])
  ]))
}