
import { defineComponent, ref } from 'vue'
import { errorMixin } from '@/plugins/error.plugin'
export default defineComponent({
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  }
})
