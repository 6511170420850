<template>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data() {
      return {
        
      }
  },
  components: {

  },

}
</script>
