import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-51982ccc")
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "uaz-card" }
const _hoisted_3 = { class: "uaz-wrapper uaz-recovery" }
const _hoisted_4 = { class: "uaz-card-registration" }
const _hoisted_5 = { class: "uaz-modal-form uaz-indent-card" }
const _hoisted_6 = { class: "uaz-support-description" }
const _hoisted_7 = { class: "uaz-card-indent uaz-indent-card" }
const _hoisted_8 = { class: "uaz-card-button" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", {
      class: "uaz-forgot",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args)))
    }, _toDisplayString(_ctx.$t('passwordForget')), 1),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShow,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.isShow = $event)),
      close: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("section", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('passwordRecovery')), 1),
                _createElementVNode("span", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                  class: "uaz-modal-close"
                }, "x")
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t('elisRecovery')), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('emailPasswordRecovery')), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('email')) + ":", 1),
                _withDirectives(_createElementVNode("input", {
                  placeholder: "",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.user_email = $event)),
                  type: "text"
                }, null, 512), [
                  [_vModelText, _ctx.user_email]
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.restorePassword && _ctx.restorePassword(...args))),
                  class: "uaz-card-submit uaz-card-close"
                }, _toDisplayString(_ctx.$t('send').toUpperCase()), 1)
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "close"])
  ], 64))
}