import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    class: "input_password",
    modelValue: _ctx.input,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.input = $event)),
    "show-password": ""
  }, null, 8, ["modelValue"]))
}