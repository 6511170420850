<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 75 33"
    style="enable-background:new 0 0 75 33;"
  >
    <g>
      <g>
        <text class="header_st0" x="3" y="16">
          <tspan>
            ELIS
          </tspan>
        </text>
        <text class="header_st1" x="3" y="26">
          <tspan>
            {{ $t('electronic') }}
          </tspan>
        </text>
        <text class="header_st1" x="3" y="33">
          <tspan>
            {{ $t('catalogue') }}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
</template>
<style scoped>
.header_st0 {
  fill: #014637;
  font-size: 22px;
  font-weight: 800;
  font-family: Arial;
  letter-spacing: 1px;
}
.header_st1 {
  fill: #014637;
  font-size: 8px;
  font-family: Arial;
  color: #014637;
  font-weight: 600;
  letter-spacing: -0.03em;
}
</style>
