<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 75 33"
    style="enable-background:new 0 0 75 33;"
  >
    <g>
      <g>
        <text class="header_st1" x="3" y="26">
          <tspan>
            {{ $t('electronic') }}
          </tspan>
        </text>
        <text class="header_st1" x="3" y="33">
          <tspan>
            {{ $t('catalogue') }}
          </tspan>
        </text>
      </g>
      <g>
        <path
          class="header_st0"
          d="M64.2,3.9c1.8,0,2.7,0.7,3.3,1.3c0.3,0.3,0.7,0.2,0.8,0.2c0.7-0.2,2.3-0.7,3-0.9c0.1,0,0.2-0.1,0.2-0.2
                            s0-0.2,0-0.3c-1.9-3.6-5.7-4-7.3-4c-3.9,0-8.1,2.5-8.1,8.1c0,5.3,4.1,8,8.1,8c3.4,0,6-1.4,7.2-3.8c0.1-0.1,0.1-0.3,0-0.4
                            c0-0.1-0.1-0.2-0.3-0.3c-0.5-0.2-1.8-0.6-2.6-0.9l-0.3-0.1c-0.3-0.1-0.6,0-0.8,0.3c-0.7,0.8-1.8,1.3-3.3,1.3
                            c-2.2,0-3.7-1.7-3.7-4.1C60.5,5.6,62,3.9,64.2,3.9z"
        />
        <path
          class="header_st0"
          d="M54.1,0.3h-2.7c-0.1,0-0.2,0-0.2,0.1l-7.9,7.8c-0.2,0.2-0.6,0.1-0.6-0.2V0.6c0-0.2-0.1-0.3-0.3-0.3h-3.3
                            c-0.2,0-0.3,0.1-0.3,0.3v14.9c0,0.2,0.1,0.3,0.3,0.3h2.7c0.1,0,0.2,0,0.2-0.1l7.9-8.1c0.2-0.2,0.6-0.1,0.6,0.2v7.7
                            c0,0.2,0.1,0.3,0.3,0.3h3.3c0.2,0,0.3-0.1,0.3-0.3V0.6C54.4,0.4,54.3,0.3,54.1,0.3z"
        />
        <path
          class="header_st0"
          d="M31.1,0.5c0-0.1-0.2-0.2-0.3-0.2h-5.7c-0.1,0-0.2,0.1-0.3,0.2l-6.6,14.9c-0.1,0.2,0.1,0.4,0.3,0.4H23
                            c0.1,0,0.2-0.1,0.3-0.2l4.3-10.9c0-0.1,0.1-0.2,0.2-0.2H28c0.1,0,0.2,0.1,0.2,0.2l4.3,10.9c0,0.1,0.2,0.2,0.3,0.2h4.6
                            c0.2,0,0.4-0.2,0.3-0.4L31.1,0.5z"
        />
        <path
          class="header_st0"
          d="M10.8,0c-3.4,0-6,1.4-7.2,3.8c-0.1,0.1-0.1,0.3,0,0.4c0,0.1,0.1,0.2,0.3,0.3c0.5,0.2,1.8,0.6,2.6,0.9l0.3,0.1
                            c0.3,0.1,0.6,0,0.8-0.3c0.7-0.8,1.8-1.3,3.3-1.3c1.7,0,2.9,1,3.5,2.5h-3.9c-0.2,0-0.3,0.1-0.3,0.3v2.8c0,0.2,0.1,0.3,0.3,0.3h3.9
                            c-0.5,1.5-1.8,2.4-3.5,2.4c-1.8,0-2.7-0.7-3.3-1.3c-0.3-0.3-0.7-0.2-0.8-0.2c-0.7,0.2-2.3,0.7-3,0.9c-0.1,0-0.2,0.1-0.2,0.2
                            c0,0.1,0,0.2,0,0.3c1.9,3.6,5.7,3.9,7.3,3.9c3.9,0,8.1-2.5,8.1-8.1C18.9,2.8,14.8,0,10.8,0z"
        />
      </g>
    </g>
  </svg>
</template>
<style scoped>
.header_st0,
.header_st1 {
  fill: #014637;
}
.header_st1 {
  font-size: 8px;
  font-family: Arial;
  color: #014637;
  font-weight: 600;
  letter-spacing: -0.03em;
}
</style>
