
import { ref } from "vue";

export default {
  data() {
    return {
      url: ref(this.src),
      srcList: ref([this.src])
    };
  },
  mounted() {},
  watch: {
    src(newValue, oldValue) {
      if (newValue) {
        this.url =  ref(newValue),
        this.srcList =  ref([newValue])        
      }
    },
  },
  updated: function () {
    console.log('OnceImage updated ')
  },  
  props: ["src"]  
};
