
const axios = require('axios')
import { Request } from '@/services/axios.service'
import Modal_reg from '@/components/Modal_reg.vue'
import Modal_forgot_password from '@/components/Modal_forgot_password.vue'
import { errorMixin } from '@/plugins/error.plugin'
import Password_box from '@/components/Password_box.vue'
import Modal_confirm from '@/components/Modal_confirm.vue'
export default {
  mixins: [errorMixin],
  name: 'Register',
  data() {
    return {
      ruleFormRef: Object.assign({}, this.ruleForm),
      isShow: false,
      ruleForm: {
        user_lname: '',
        user_fname: '',
        user_mname: '',
        user_phone: '',
        user_email: '',
        user_password: '',
        passwordConfirm: '',
        personal: false
      },
      rules: {
        user_lname: [
          {
            required: false,
            message: this.$t('fillSurname'),
            trigger: 'change'
          }
        ],
        user_fname: [
          {
            required: false,
            message: this.$t('fillName'),
            trigger: 'change'
          }
        ],
        /*user_mname: [
            {
              required: true,
              message: 'Fill Fathers Name',
              trigger: 'change',
            },
          ],*/
        user_phone: [
          {
            pattern:
              '^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$',
            required: false,
            message: this.$t('fillPhone'),
            trigger: 'change'
          }
        ],
        user_email: [
          {
            type: 'email',
            required: true,
            message: this.$t('fillEmail'),
            trigger: 'change'
          }
        ],
        user_password: [
          {
            required: true,
            message: this.$t('fillPassword'),
            trigger: 'change'
          }
        ],
        passwordConfirm: [
          {
            validator: this.validatePass2,
            required: true,
            trigger: 'change'
          }
        ],
        personal: [
          {
            transform: value => String(value),
            type: 'enum',
            enum: ['true'],
            required: true,
            message: this.$t('agreementRequired'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  components: {
    'Modal-reg': Modal_reg,
    'Modal-forgot-password': Modal_forgot_password,
    'password-box': Password_box,
    'Modal-confirm': Modal_confirm
  },
  methods: {
    submitForm: function(formEl) {
      this.$refs.ruleFormRef.validate(valid => {
        if (valid) {
          return Request.post('user/update/', this.ruleForm)
            .then(response => {})
            .catch(function(error) {
              errorMixin.methods.showError('error', error.response.data.message)
            })
        } else {
          return false
        }
      })
    },
    validatePass2: function(rule: any, value: any, callback: any) {
      if (value === '') {
        callback(new Error(this.$t('passwordAgain')))
      } else if (value !== this.ruleForm.user_password) {
        callback(new Error(this.$t('passwordMismatch')))
      } else {
        callback()
      }
    },
    showModal: function() {
      this.isShow = true
    },
    closeModal: function() {
      this.isShow = false
    }
  }
}
