<template>
  <Choose-modification section="flowchart" />
</template>

<script>
import Choose_modification from "@/components/Choose_modification.vue";
export default {
  name: "Flowcharts",
  data() {
    return {};
  },
  components: {
    "Choose-modification": Choose_modification,
  },
  mounted: function () {},
  methods: {},
};
</script>
<style>
</style>